import clsx from "clsx"

export const HeroContainer = ({
  className,
  children,
  ...props
}: {
  className?: string
  children: JSX.Element | JSX.Element[]
}) => {
  return (
    <div
      className={clsx("max-w-7xl px-4 sm:px-6 lg:px-8", className)}
      {...props}
    >
      {children}
    </div>
  )
}
