import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { HeroContainer } from "./components/HeroContainer"

export function Hero() {
  const { t } = useTranslation(undefined, { useSuspense: true })
  const router = useRouter()

  return (
    <div className="relative flex justify-center bg-gray-900 text-slate-50 h-dvh">
      <HeroContainer className="relative pt-20 pb-20 lg:pb-60 lg:pt-32">
        <h1 className="pl-3 relative font-display mx-auto max-w-3xl text-5xl font-medium tracking-tight sm:text-7xl">
          {t(
            "Supercharge Your Software Development with AI-Powered Specs and Tests"
          )}
        </h1>
        <p className="pl-3 relative mt-6 max-w-2xl text-left text-lg tracking-tight">
          {t(
            "Harness the power of AI to craft bulletproof specifications and generate comprehensive test cases. Say goodbye to oversights and hello to rock-solid systems."
          )}
        </p>
        <div className="mt-10">
          <button
            className="px-8 py-3 font-semibold text-lg bg-blue-500 rounded-full shadow-lg hover:bg-blue-600 transition duration-300 ease-in-out"
            onClick={() => {
              router.push("/auth/sign-up")
            }}
          >
            {t("Get Started Free")}
          </button>
        </div>
      </HeroContainer>
    </div>
  )
}
