import Head from "next/head"

// import { CallToAction } from "@/components/landing/CallToActiona"
import { Footer } from "@/components/landing/Footer"
import { Header } from "@/components/landing/Header"
import { Hero } from "@/components/landing/Hero"
import { GetServerSideProps } from "next"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import { Faqs } from "../components/landing/Faqs"
import { PrimaryDocuments } from "../components/landing/PrimaryFeatures"
import { Problems } from "../components/landing/Problems"
import useAuth from "../hooks/useAuth"
import { LandingPagePricing } from "../components/billing/LandingPagePricing"

export default function Home() {
  const pageTitle = "GenieSpec - Write Spec and Test Cases in One Place!"
  const pageDescription = "GenieSpec is AI software specification editor"
  // const pageImage = "http://localhost:1234/static/img/thumbnail.png" // URL to your thumbnail image
  // const pageImage = "https://readia-app.com/static/img/thumbnail.png" // URL to your thumbnail image
  const { user } = useAuth()
  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href="https://geniespec.com" />

        {/* Open Graph Tags */}
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        {/* <meta property="og:image" content={pageImage} /> */}
        <meta property="og:url" content="https://geniespec.com" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        {/* <meta name="twitter:image" content={pageImage} /> */}
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:title" content={pageTitle} />
        {/* Additional meta tags as needed */}
      </Head>
      <Header loggedIn={!!user.id} />
      <main>
        <Hero />
        <Problems />
        <PrimaryDocuments />
        {/* <MobileAppPrimaryDocuments /> */}
        {/* <Pricing /> */}
        {/* <SecondaryDocuments /> */}
        {/* <CallToAction /> */}
        <LandingPagePricing />
        <Faqs />
      </main>
      <Footer />
    </>
  )
}

export const getServerSideProps: GetServerSideProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale ?? "en", ["common", "header"]))
    }
  }
}
