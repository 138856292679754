import { useContext } from "react"

import { dummyAuthContext } from "../types/auth"
import { AuthContext } from "../contexts/SupabaseAuthContext"

const useAuth = () => {
  const context = useContext(AuthContext)

  if (!context) {
    return dummyAuthContext
  }

  return context
}

export default useAuth
