import { useTranslation } from "next-i18next"
import Image from "next/image"
import { useEffect, useRef, useState } from "react"

import { Container } from "./components/Container"
import featureImage1 from "./images/screenshots/specification-check.png" // Placeholder images
import featureImage2 from "./images/screenshots/testcase-generation.png" // Placeholder images

export function PrimaryDocuments() {
  const { t } = useTranslation(undefined, { useSuspense: true })
  const [isMobile, setIsMobile] = useState(false)
  const featureRefs = useRef<(HTMLDivElement | null)[]>([])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640)
    }

    handleResize()

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible")
            entry.target.classList.remove("not-visible")
          } else {
            entry.target.classList.remove("visible")
            entry.target.classList.add("not-visible")
          }
        })
      },
      { threshold: 0.1 }
    )

    featureRefs.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref)
      }
    })

    return () => {
      if (featureRefs.current) {
        featureRefs.current.forEach((ref) => {
          if (ref) {
            observer.unobserve(ref)
          }
        })
      }
    }
  }, [])

  const features = [
    {
      title: t("AI-Powered Spec Validation"),
      description: t(
        "Say goodbye to missed requirements. Our AI ensures your project specs are rock-solid."
      )
    },
    {
      title: t("Smart Test Case Generator"),
      description: t(
        "Craft bulletproof test cases in a snap. Let AI turn your requirements into comprehensive tests."
      )
    }
  ]

  return (
    <section
      id="features"
      aria-label="Primary features of GenieSpec"
      className="relative overflow-hidden bg-slate-900 pt-12 md:pb-10"
    >
      <Container className="relative">
        <div className="max-w-2xl md:mx-auto md:text-center xl:max-w-none">
          <h2 className="font-display text-3xl tracking-tight text-slate-50 sm:text-4xl md:text-5xl">
            {t("Supercharge Your Dev Workflow")}
          </h2>
          <p className="mt-6 text-lg tracking-tight text-gray-300">
            {t(
              "Boost your project management and streamline testing with our cutting-edge AI tools."
            )}
          </p>
        </div>

        <div className="mt-16 space-y-10">
          {features.map((feature, index) => (
            <div
              key={index}
              ref={(el) => {
                featureRefs.current[index] = el
              }}
              className={`fade-in not-visible flex flex-col md:flex-row ${
                index % 2 === 1 ? "md:flex-row-reverse" : ""
              } items-center mb-10`}
            >
              <div className="md:w-1/2 p-6 self-start">
                <h2 className="font-display text-lg text-gray-300">
                  {feature.title}
                </h2>
                <p className="mt-2 text-sm text-gray-200">
                  {feature.description}
                </p>
              </div>
              <div className="md:w-1/2 p-6">
                <Image
                  className="h-full w-full rounded"
                  src={index === 0 ? featureImage1 : featureImage2}
                  alt=""
                  priority
                  sizes="(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem"
                />
              </div>
            </div>
          ))}
        </div>
      </Container>
    </section>
  )
}
