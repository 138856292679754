import { useEffect, useRef } from "react"
import { useTranslation } from "next-i18next"
import { Container } from "./components/Container"

export function Problems() {
  // Initialize translation hook
  const { t } = useTranslation("common", { useSuspense: true })

  // Create a ref to store DOM elements for IntersectionObserver
  const problemRefs = useRef<(HTMLDivElement | null)[]>([])

  useEffect(() => {
    // Create an IntersectionObserver to handle element visibility on scroll
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible")
            entry.target.classList.remove("not-visible")
          } else {
            entry.target.classList.remove("visible")
            entry.target.classList.add("not-visible")
          }
        })
      },
      { threshold: 0.1 }
    )

    // Observe each ref element
    problemRefs.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref)
      }
    })

    // Clean up observer on component unmount
    return () => {
      if (problemRefs.current) {
        problemRefs.current.forEach((ref) => {
          if (ref) {
            observer.unobserve(ref)
          }
        })
      }
    }
  }, [])

  // List of common development problems
  const problems = [
    {
      text: t("Struggling to catch all important specifications?"),
      icon: "📋",
      color: "bg-blue-100"
    },
    {
      text: t(
        "Frustrated by gaps between documentation and actual implementation?"
      ),
      icon: "📚",
      color: "bg-green-100"
    },
    {
      text: t("Finding it tough to create comprehensive test cases?"),
      icon: "🧪",
      color: "bg-red-100"
    }
  ]

  return (
    <div className="bg-slate-900 py-20">
      <Container>
        <h2 className="text-3xl font-semibold text-center text-white mb-12">
          {t("Facing These Common Development Hurdles?")}
        </h2>
        <div className="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {problems.map((problem, index) => (
            <div
              key={index}
              ref={(el) => {
                problemRefs.current[index] = el
              }}
              className={`fade-in not-visible ${problem.color} p-6 rounded-lg shadow-sm 
                          flex flex-col items-center space-y-4 transition-all duration-300 
                          transform hover:scale-102 hover:shadow-md`}
            >
              <div className="text-4xl mb-2 opacity-80">{problem.icon}</div>
              <p className="text-lg text-gray-700 text-center font-normal">
                {problem.text}
              </p>
            </div>
          ))}
        </div>
      </Container>
    </div>
  )
}
