import { useEffect, useRef } from "react"
import { useTranslation } from "next-i18next"
import { Container } from "./components/Container"

export function Faqs() {
  const { t } = useTranslation()
  const faqRefs = useRef<(HTMLLIElement | null)[]>([])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible")
            entry.target.classList.remove("not-visible")
          } else {
            entry.target.classList.remove("visible")
            entry.target.classList.add("not-visible")
          }
        })
      },
      { threshold: 0.1 }
    )

    faqRefs.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref)
      }
    })

    return () => {
      if (faqRefs.current) {
        faqRefs.current.forEach((ref) => {
          if (ref) {
            observer.unobserve(ref)
          }
        })
      }
    }
  }, [])

  const faqs = [
    {
      question: t("Is our data used for AI learning?"),
      answer: t(
        "No, it is not used. The AI in GenieSpec uses Google's Gemini Paid API, which states that data such as prompts and responses are not used for learning. For details, see https://ai.google.dev/gemini-api/terms#data-use-paid"
      )
    }
  ]

  return (
    <section
      id="faq"
      aria-labelledby="faq-title"
      className="relative overflow-hidden bg-slate-900 py-20 sm:py-32"
    >
      <Container className="relative">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2
            id="faq-title"
            className="font-display text-3xl tracking-tight text-slate-50 sm:text-4xl"
          >
            {t("Frequently Asked Questions")}
          </h2>
          <p className="mt-4 text-lg tracking-tight text-slate-50">
            {t("Find answers to the most common questions below.")}
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-1"
        >
          {faqs.map((faq, index) => (
            <li
              key={index}
              ref={(el) => {
                faqRefs.current[index] = el
              }}
              className="fade-in not-visible"
            >
              <h3 className="font-display text-lg leading-7 text-slate-50">
                {faq.question}
              </h3>
              <p className="mt-4 text-sm text-slate-50">
                {faq.answer}
                <br />
                <a
                  href="https://ai.google.dev/gemini-api/terms#data-use-paid"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-300 underline"
                >
                  {t("Learn more")}
                </a>
              </p>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  )
}
